import { BaseHit, Hit as AlgoliaHit } from 'instantsearch.js';
import { Product, Promotions, Variation } from '@framework/types';

export type HitProps = AlgoliaHit<
  BaseHit & {
    objectID: string;
    price_range: string;
    brand: string;
    categories: any;
    longDescription: string;
    description: string;
    image: any;
    gallery: any;
    name: string;
    slug: string;
    price: number;
    type: string;
    Id: string;
    sku: string;
    variations: Variation[];
    promotions: Promotions[];
    rating: number;
  }
>;

export const formatProductData = (hit: HitProps): Product => {
  const {
    brand,
    categories,
    longDescription,
    description,
    image,
    gallery,
    name,
    slug,
    price,
    objectID,
    price_range,
    type,
    sku,
    variations,
    promotions,
    rating,
    Id,
  } = hit ?? {};
  let min_price = 0;
  let max_price = 0;

  if (price_range) {
    const prices = price_range.split('-');
    min_price = Number(prices[0]);
    max_price = Number(prices[1]);
  }

  const galleryArr = gallery.length ? gallery : [];

  return {
    id: Id || objectID,
    name,
    slug,
    price,
    quantity: 100,
    sold: 0,
    unit: brand || type || '',
    min_price,
    max_price,
    image: {
      id: objectID,
      thumbnail: image,
      original: image,
    },
    gallery: galleryArr,
    category: categories,
    brand,
    longDescription,
    description,
    product_type: price_range ? 'variations' : '',
    sku,
    variations: variations || [],
    promotions: promotions || [],
    rating,
  };
};
