import { TokenService } from 'src/domain/commerce/token/token-service';
import { UserCredentials } from 'src/domain/commerce/token/user-credentials';

const getAuthToken = async (
  service: TokenService,
  userCredentials: UserCredentials,
) => {
  return await service.getAccessToken(
    userCredentials.username,
    userCredentials.password,
  );
};

const getAnonymousToken = async (service: TokenService) => {
  return await service.getAnonymousToken();
};

export { getAuthToken, getAnonymousToken };
