import { useMutation, useQuery } from '@tanstack/react-query';
import { useCart } from '@contexts/cart/cart.context';
import { mapLineItemsToItems } from '@utils/mappers/basket/ish-lineitem-mapper';
import { LineItem } from 'src/domain/commerce/basket/lineitem';
import { AlgoliaProductGateway } from 'src/gateway/algolia/algolia-product-gateway';
import { IntershopBasketGateway } from 'src/gateway/commerce/ish-basket-gateway';
import { IntershopTokenGateway } from 'src/gateway/commerce/ish-token-gateway';
import { addToCart } from 'src/use-cases/add-to-cart';
import { editCartLineItem } from 'src/use-cases/edit-cart-lineitem';
import { getAnonymousToken } from 'src/use-cases/get-ish-auth-token';
import { removeFromCart } from 'src/use-cases/remove-from-cart';
import { getCartItems } from 'src/use-cases/get-cart-items';
import { clearCartItems } from 'src/use-cases/clear-cart-items';
import TokenProvider from '@framework/utils/token-provider';
import { getAlgoliaProductsBySkuOrVariantSku } from 'src/use-cases/get-algolia-products';

export interface AddToCartInput {
  sku: string | undefined;
  quantity: number;
}

export interface UpdateLineItemInput {
  lineItemId: string | undefined;
  quantity: number;
}

export interface DeleteFromCartInput {
  lineItemId: string | undefined;
}

const cartController = () => {
  const addProductToCart = async (input: AddToCartInput) => {
    if (!input.sku) {
      throw new Error('SKU is empty when adding product to cart');
    }
    let { access_token } = TokenProvider.getToken() ?? {};
    if (!access_token) {
      const anonymousToken = await getAnonymousToken(IntershopTokenGateway());
      TokenProvider.setToken(anonymousToken);
      access_token = anonymousToken.access_token;
    }

    return await addToCart(
      IntershopBasketGateway(),
      access_token,
      input.sku,
      input.quantity,
    );
  };

  const useAddToCartMutation = () => {
    const { setCart } = useCart();
    return useMutation({
      mutationFn: (input: AddToCartInput) => addProductToCart(input),
      onSuccess: async (lineItems) => await syncCartState(lineItems, setCart),
    });
  };

  const syncCartState = async (lineItems: LineItem[], setCart: Function) => {
    const skus = lineItems.map((item) => item.sku);
    const algoliaProducts = await getAlgoliaProductsBySkuOrVariantSku(
      AlgoliaProductGateway(),
      skus,
    );
    const mappedItems = mapLineItemsToItems(lineItems, algoliaProducts);
    setCart(mappedItems);
  };

  const updateProductInCart = async (input: UpdateLineItemInput) => {
    const { lineItemId, quantity } = input;
    if (!lineItemId) {
      throw new Error('Lineitem ID is empty when updating product in cart');
    }

    const { access_token } = TokenProvider.getToken() ?? {};
    if (!access_token) {
      throw new Error(
        'Something went wrong while updating an item in the cart',
      );
    }

    if (quantity < 1) {
      return await removeFromCart(
        IntershopBasketGateway(),
        access_token,
        lineItemId,
      );
    } else {
      return await editCartLineItem(
        IntershopBasketGateway(),
        access_token,
        lineItemId,
        quantity,
      );
    }
  };

  const useUpdateCartLineItemMutation = () => {
    const { setCart } = useCart();
    return useMutation({
      mutationFn: (input: UpdateLineItemInput) => updateProductInCart(input),
      onSuccess: async (lineItems) => await syncCartState(lineItems, setCart),
    });
  };

  const useDeleteFromCartMutation = () => {
    const { setCart } = useCart();
    return useMutation({
      mutationFn: (input: DeleteFromCartInput) =>
        updateProductInCart({ ...input, quantity: 0 }),
      onSuccess: async (lineItems) => await syncCartState(lineItems, setCart),
    });
  };

  const getCart = async () => {
    const { access_token } = TokenProvider.getToken() ?? {};
    if (!access_token) {
      return [];
    }

    return await getCartItems(IntershopBasketGateway(), access_token);
  };

  const useGetCart = () => {
    const { setCart, resetCart } = useCart();
    return useQuery({
      queryKey: ['getCart'],
      queryFn: getCart,
      onSuccess: (lineItems) => {
        (async () => await syncCartState(lineItems, setCart))();
      },
      onError: (err: any) => {
        resetCart();
      },
    });
  };

  const clearCart = async () => {
    const { access_token } = TokenProvider.getToken() ?? {};
    if (!access_token) {
      throw new Error('Something went wrong while clearing the cart');
    }

    return await clearCartItems(IntershopBasketGateway(), access_token);
  };

  const useClearCart = () => {
    return useQuery({
      queryKey: ['clearCart'],
      queryFn: () => clearCart().then((response) => response.data),
      enabled: false,
    });
  };

  return {
    addToCart: useAddToCartMutation,
    updateCartLineItem: useUpdateCartLineItemMutation,
    deleteFromCart: useDeleteFromCartMutation,
    getCart: useGetCart,
    clearCart: useClearCart,
    syncCart: syncCartState,
  };
};

export default cartController();
