import { COOKIE_KEYS } from '@utils/constants/cookies';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { BasketService } from 'src/domain/commerce/basket/basket-service';
import { AddLineItemRequest } from 'src/domain/commerce/basket/lineitem';

export const addToCart = async (
  service: BasketService,
  authToken: string,
  sku: string,
  quantity: number,
) => {
  await createBasketIfNotExist(service, authToken);
  const addLineItemRequest = mapAddLineItemRequest(sku, quantity);
  await service.addLineItemCurrentBasket(authToken, addLineItemRequest);
  return await service.getCurrentBasketLineItems(authToken);
};

const createBasketIfNotExist = async (
  service: BasketService,
  authToken: string,
) => {
  try {
    return await service.getCurrentBasket(authToken);
  } catch (err) {
    // if basket not found, create it
    if (err instanceof AxiosError && err?.response?.status === 404) {
      const createCartResponse = await service.createBasket(authToken);
      Cookies.set(COOKIE_KEYS.BASKET_ID, createCartResponse.id);
      return createCartResponse;
    } else {
      throw err;
    }
  }
};

const mapAddLineItemRequest = (
  sku: string,
  quantity: number,
): AddLineItemRequest[] => {
  return [
    {
      product: sku,
      quantity: {
        value: quantity,
        unit: '',
      },
    },
  ];
};
