import axios from 'axios';
import { Basket, ClearBasketResponse } from 'src/domain/commerce/basket/basket';
import { BasketService } from 'src/domain/commerce/basket/basket-service';
import { ACCEPT_TYPES, HEADERS } from '@utils/constants/http-headers';
import { intershopSettings } from '@settings/intershop-settings';
import {
  AddLineItemRequest,
  LineItem,
  UpdateLineItemRequest,
} from 'src/domain/commerce/basket/lineitem';
import {
  mapLineItem,
  mapLineItems,
} from '@utils/mappers/basket/ish-lineitem-mapper';

const settings = intershopSettings[process.env.NODE_ENV];

const IntershopBasketGateway = (): BasketService => {
  const createBasket = async (authToken: string): Promise<Basket> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    const response = await axios.post(
      `${settings.baseUrl}${settings.endpoints.basket}`,
      {},
      config,
    );

    return {
      id: response.data.data.id,
    };
  };

  const getCurrentBasket = async (authToken: string): Promise<Basket> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    const response = await axios.get(
      `${settings.baseUrl}${settings.endpoints.basket}/current`,
      config,
    );

    return {
      id: response.data.id,
    };
  };

  const addLineItemCurrentBasket = async (
    authToken: string,
    lineItems: AddLineItemRequest[],
  ): Promise<LineItem[]> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    const response = await axios.post(
      `${settings.baseUrl}${settings.endpoints.basket}/current/items`,
      lineItems,
      config,
    );

    return mapLineItems(response);
  };

  const getCurrentBasketLineItems = async (
    authToken: string,
  ): Promise<LineItem[]> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    const response = await axios.get(
      `${settings.baseUrl}${settings.endpoints.basket}/current/items`,
      config,
    );

    return mapLineItems(response);
  };

  const updateCurrentBasketLineItem = async (
    authToken: string,
    lineItemId: string,
    updatedLineItem: UpdateLineItemRequest,
  ): Promise<LineItem> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    const response = await axios.patch(
      `${settings.baseUrl}${settings.endpoints.basket}/current/items/${lineItemId}`,
      updatedLineItem,
      config,
    );

    return mapLineItem(response.data.data);
  };

  const deleteCurrentBasketLineItem = async (
    authToken: string,
    lineItemId: string,
  ): Promise<void> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    await axios.delete(
      `${settings.baseUrl}${settings.endpoints.basket}/current/items/${lineItemId}`,
      config,
    );
  };

  const clearCurrentBasket = async (
    authToken: string,
  ): Promise<ClearBasketResponse> => {
    const config = {
      headers: {
        [HEADERS.AUTHENTICATION_TOKEN]: authToken,
        [HEADERS.ACCEPT]: ACCEPT_TYPES.BASKET_JSON,
      },
    };

    return await axios.delete(
      `${settings.baseUrl}${settings.endpoints.basket}/current/items`,
      config,
    );
  };

  return {
    createBasket,
    getCurrentBasket,
    addLineItemCurrentBasket,
    getCurrentBasketLineItems,
    updateCurrentBasketLineItem,
    deleteCurrentBasketLineItem,
    clearCurrentBasket,
  };
};

export { IntershopBasketGateway };
