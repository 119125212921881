import algoliasearch from 'algoliasearch';
import { getAppConfig } from '@utils/get-app-config';
import { GetProductsByCategoryParams, Product } from '@framework/types';
import { HitProps, formatProductData } from '@utils/format-product-data';
import algoliaProductMapper from '@utils/mappers/product/algolia-product-mapper';
import { ProductService } from 'src/domain/search/product-service';

const config = getAppConfig();
const searchClient = algoliasearch(config.ALGOLIA_APPID, config.ALGOLIA_APIKEY);
const index = searchClient.initIndex(config.ALGOLIA_INDEXNAME);

const AlgoliaProductGateway = (): ProductService => {
  const getProductsFromSKU = async (skus: string[]): Promise<Product[]> => {
    if (!skus.length) {
      return [];
    }

    const filterText = skus.map((sku: string) => `(sku:"${sku}")`).join(' OR ');
    const { hits } = await index.search('', {
      filters: filterText,
    });

    return algoliaProductMapper(hits as HitProps[]).filter(
      (response) => response !== null,
    );
  };

  const getProductBySlug = async (slug: string): Promise<Product> => {
    if (!slug) {
      throw new Error(`Product not found, Slug: ${slug}`);
    }

    const { hits } = await index.search('', {
      filters: `(slug:"${slug}")`,
    });

    if (hits.length < 1) {
      throw new Error(`Product not found, Slug: ${slug}`);
    }

    return hits
      .map((hit) => formatProductData(hit as HitProps))
      .filter((response) => response !== null)[0];
  };

  const getProductsFromSkuOrVariantSku = async (
    skus: string[],
  ): Promise<Product[]> => {
    if (!skus.length) {
      return [];
    }

    const filterText = skus
      .map((sku: string) => `(sku:"${sku}") OR (variations.sku:"${sku}")`)
      .join(' OR ');
    const { hits } = await index.search('', {
      filters: filterText,
    });

    if (!hits.length) {
      const skuSum = skus.join(', ');
      throw new Error(`Products not found, skus: ${skuSum}`);
    }

    return hits
      .map((hit) => formatProductData(hit as HitProps))
      .filter((response) => response !== null);
  };

  const getProductByCategory = async ({
    category,
    hitsPerPage = 20,
  }: GetProductsByCategoryParams): Promise<Product[]> => {
    if (!category) {
      throw new Error(`Product not found, category: ${category}`);
    }

    const { hits } = await index.search('', {
      filters: `(categories.slug:"${category}")`,
      hitsPerPage:
        !hitsPerPage || hitsPerPage < 1 || isNaN(hitsPerPage)
          ? 20
          : hitsPerPage,
    });

    if (hits.length < 1) {
      throw new Error(`Product not found, category: ${category}`);
    }

    return hits
      .map((hit) => formatProductData(hit as HitProps))
      .filter((response) => response !== null);
  };

  return {
    getProductsFromSKU,
    getProductBySlug,
    getProductsFromSkuOrVariantSku,
    getProductByCategory,
  };
};

export { AlgoliaProductGateway };
