/* istanbul ignore file */
const restEndpoint = '/inSPIRED-inTRONICS_Business-Site/rest';

export const intershopSettings = {
  development: {
    domain: 'https://smartstore.salnl.net',
    baseUrl: 'https://smartstore.salnl.net/INTERSHOP/rest/WFS',
    endpoints: {
      product: `${restEndpoint}/products`,
      category: `${restEndpoint}/categories`,
      token: `${restEndpoint}/token`,
      customer: `${restEndpoint}/customers`,
      basket: `${restEndpoint}/baskets`,
    },
  },
  test: {
    domain: 'https://smartstore.salnl.net',
    baseUrl: 'https://smartstore.salnl.net/INTERSHOP/rest/WFS',
    endpoints: {
      product: `${restEndpoint}/products`,
      category: `${restEndpoint}/categories`,
      token: `${restEndpoint}/token`,
      customer: `${restEndpoint}/customers`,
      basket: `${restEndpoint}/baskets`,
    },
  },
  production: {
    domain: 'https://smartstore.salnl.net',
    baseUrl: 'https://smartstore.salnl.net/INTERSHOP/rest/WFS',
    endpoints: {
      product: `${restEndpoint}/products`,
      category: `${restEndpoint}/categories`,
      token: `${restEndpoint}/token`,
      customer: `${restEndpoint}/customers`,
      basket: `${restEndpoint}/baskets`,
    },
  },
};
