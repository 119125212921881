import { BasketService } from 'src/domain/commerce/basket/basket-service';
import { UpdateLineItemRequest } from 'src/domain/commerce/basket/lineitem';

export const editCartLineItem = async (
  service: BasketService,
  authToken: string,
  lineItemId: string,
  quantity: number,
) => {
  const updateLineItemRequest = mapUpdateLineItemRequest(quantity);
  await service.updateCurrentBasketLineItem(
    authToken,
    lineItemId,
    updateLineItemRequest,
  );
  return await service.getCurrentBasketLineItems(authToken);
};

const mapUpdateLineItemRequest = (quantity: number): UpdateLineItemRequest => {
  return {
    quantity: {
      value: quantity,
    },
  };
};
