import { BasketService } from 'src/domain/commerce/basket/basket-service';

export const removeFromCart = async (
  service: BasketService,
  authToken: string,
  lineItemId: string,
) => {
  await service.deleteCurrentBasketLineItem(authToken, lineItemId);
  return await service.getCurrentBasketLineItems(authToken);
};
