import { Product } from '@framework/types';
import { HitProps } from '@utils/format-product-data';

export function recommendedProductMapper(elements: any[]): string[] {
  return elements.map(({ objectID }) => objectID);
}

export default function algoliaProductMapper(elements: HitProps[]): Product[] {
  return elements.map(
    ({
      objectID,
      Id,
      slug,
      sku,
      name,
      price,
      brand,
      image,
      description,
    }: HitProps) => ({
      id: Id || objectID,
      slug: slug || '',
      name: name || '',
      price: price || 0,
      quantity: 100,
      sold: 7,
      unit: brand || '',
      image: {
        id: 1,
        thumbnail: image || '',
        original: image || '',
      },
      sku: sku || objectID,
      description: description || '',
    }),
  );
}
