import { Item } from '@contexts/cart/cart.utils';
import { LineItem } from 'src/domain/commerce/basket/lineitem';
import { intershopSettings } from '@settings/intershop-settings';
import { Product } from '@framework/types';

const settings = intershopSettings[process.env.NODE_ENV];

export function mapLineItems(apiData: any): LineItem[] {
  const mappedLineItems: LineItem[] = new Array();
  const lineItems = apiData?.data?.data || [];

  lineItems.forEach((lineItem: any) => {
    const mappedLineItem = mapLineItem(lineItem);
    mappedLineItems.push(mappedLineItem);
  });

  return mappedLineItems;
}

export function mapLineItem(lineItem: any): LineItem {
  return {
    id: lineItem.id,
    sku: lineItem.product,
    quantity: lineItem.quantity.value,
    price: lineItem.pricing.singleBasePrice?.net.value,
    image: lineItem.thumbnail
      ? `${settings.domain}${lineItem.thumbnail}`
      : undefined,
  };
}

export function mapLineItemsToItems(
  lineItems: LineItem[],
  algoliaProducts: Product[] = [],
) {
  const mappedItems: Item[] = new Array();
  lineItems.forEach((lineItem) => {
    const algoliaProduct = algoliaProducts.find(
      (algoliaProduct) => algoliaProduct.sku === lineItem.sku,
    );
    if (algoliaProduct) {
      const mappedItem = mapAlgoliaProductToItem(lineItem, algoliaProduct);
      mappedItems.push(mappedItem);
      return;
    }

    const algoliaProductVariant = algoliaProducts.find(
      (algoliaProduct) =>
        !!algoliaProduct.variations?.find(
          (variation) => variation.sku === lineItem.sku,
        ),
    );
    if (algoliaProductVariant) {
      const mappedItem = mapAlgoliaProductVariantToItem(
        lineItem,
        algoliaProductVariant,
      );
      mappedItems.push(mappedItem);
      return;
    }

    console.error(`Product not found, SKU: ${lineItem.sku}`);
  });
  return mappedItems;
}

function mapAlgoliaProductToItem(
  lineItem: LineItem,
  algoliaProduct: Product,
): Item {
  const item: Item = {
    id: lineItem.sku,
    lineItemId: lineItem.id,
    name: algoliaProduct?.name,
    slug: algoliaProduct?.slug,
    price: lineItem.price,
    quantity: lineItem.quantity,
    image: algoliaProduct?.image?.thumbnail ?? lineItem.image,
  };
  return item;
}

function mapAlgoliaProductVariantToItem(
  lineItem: LineItem,
  algoliaProduct: Product,
): Item {
  const variation = algoliaProduct.variations?.find(
    (variation) => variation.sku === lineItem.sku,
  );
  const attributesName = variation?.variation_attributes
    .map((attribute) => attribute.value)
    .join(', ');
  const name = `${algoliaProduct.name}, ${attributesName}`;

  const image =
    variation?.image ??
    algoliaProduct.image?.thumbnail ??
    algoliaProduct.gallery?.[0]?.thumbnail ??
    lineItem.image ??
    '../product-placeholder.svg';

  const item: Item = {
    id: lineItem.sku,
    lineItemId: lineItem.id,
    name,
    slug: algoliaProduct.slug,
    price: lineItem.price,
    quantity: lineItem.quantity,
    image,
  };
  return item;
}
