import axios from 'axios';
import { intershopSettings } from '@settings/intershop-settings';
import { AuthToken } from 'src/domain/commerce/token/auth-token';
import { TokenService } from 'src/domain/commerce/token/token-service';

const settings = intershopSettings[process.env.NODE_ENV];

const IntershopTokenGateway = (): TokenService => {
  const getAccessToken = async (
    username: string,
    password: string,
  ): Promise<AuthToken> => {
    const response = await axios.post(
      `${settings.baseUrl}${settings.endpoints.token}`,
      new URLSearchParams({
        grant_type: 'password',
        username,
        password,
      }),
    );
    const token = response.data;
    return {
      ...token,
      is_anonymous: false,
    };
  };

  const getAnonymousToken = async (): Promise<AuthToken> => {
    const response = await axios.post(
      `${settings.baseUrl}${settings.endpoints.token}`,
      new URLSearchParams({
        grant_type: 'anonymous',
      }),
    );
    const token = response.data;
    return {
      ...token,
      is_anonymous: true,
    };
  };

  return {
    getAccessToken,
    getAnonymousToken,
  };
};

export { IntershopTokenGateway };
