import { GetProductsByCategoryParams } from '@framework/types';
import { ProductService } from 'src/domain/search/product-service';

export const getAlgoliaProductsBySku = async (
  service: ProductService,
  skus: string[],
) => {
  return await service.getProductsFromSKU(skus);
};

export const getAlgoliaProductBySlug = async (
  service: ProductService,
  slug: string,
) => {
  return await service.getProductBySlug(slug);
};

export const getAlgoliaProductsBySkuOrVariantSku = async (
  service: ProductService,
  skus: string[],
) => {
  return await service.getProductsFromSkuOrVariantSku(skus);
};

export const getAlgoliaProductByCategory = async (
  service: ProductService,
  params: GetProductsByCategoryParams,
) => {
  return await service.getProductByCategory(params);
};
